<template>
  <div id="content">
    <div class="container">
      <div class="project-container printable">
        <div class="header">
          <i class="fal fa-project-diagram"></i> {{ $t("Project Details") }}

          <a class="print" @click="printPage()"
            ><i class="fal fa-print"></i>
          </a>
        </div>
        <div class="row-item inlne-flex">
          <div class="col-6 col-print-6">
            <div class="label">{{ $t("Project") }}</div>
            <div class="content">{{ pr.project_name }}</div>
          </div>
          <div class="col-6 col-print-6">
            <div class="label">{{ $t("Due Date") }}</div>
            <div class="content">{{ pr.project_name }}</div>
          </div>
        </div>

        <div class="row-item inlne-flex">
          <div class="col-12 col-print-12">
            <div class="label">{{ $t("Team") }}</div>
            <div class="content">
              <div class="pr-teams" v-if="pr.members && pr.members[0]">
                <div class="col-3 col-print-3" v-for="m in pr.members">
                  <div class="team-member">
                    <v-avatar color="success" size="42">
                      <span class="white--text headline">
                        {{ nameReduce(m[1]) }}
                      </span>
                    </v-avatar>
                    <div class="title">
                      <div class="fullname">{{ m[1] }}</div>
                      <div class="jobtitle">{{ m[2] }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pr-teams" v-else>
                <div class="member-container">
                  <div class="team-member">
                    <div
                      class="v-avatar success"
                      style="height: 42px; min-width: 42px; width: 42px"
                    >
                      <span class="white--text headline">
                        {{ nameReduce(pr.rel_record_owner_label) }}
                      </span>
                    </div>
                    <div class="title">
                      <div class="fullname">
                        {{ pr.rel_record_owner_label }}
                      </div>
                      <div class="jobtitle">{{ $t("Project Manager") }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row-item inlne-flex">
          <div class="col-7 col-print-12">
            <div class="label margin-bottom-20">
              {{ $t("Last Update") }}
              <small> ({{ pr.last_updated_on | date }}) </small>
            </div>
            <div class="content">
              {{ pr.last_update }}
            </div>
          </div>

          <div class="col-5 col-print-12">
            <div class="row">
              <div class="col col-print-3" style="width: 110px">
                <div class="content-item">
                  <span class="title-stats">{{ $t("Tasks in Backlog") }}</span>
                  <v-progress-circular
                    :rotate="360"
                    :size="100"
                    :width="4"
                    :value="pr.tasks_in_backlog_rate"
                    color="red"
                  >
                    {{ pr.tasks_in_backlog }}
                  </v-progress-circular>
                </div>
              </div>
              <div class="col col-print-3" style="width: 110px">
                <div class="content-item">
                  <span class="title-stats">{{ $t("Tasks in Dependent") }}</span>
                  <v-progress-circular
                    :rotate="360"
                    :size="100"
                    :width="4"
                    :value="pr.tasks_in_dependent_rate"
                    color="red"
                  >
                    {{ pr.tasks_in_dependent }}
                  </v-progress-circular>
                </div>
              </div>
              <div class="col col-print-3" style="width: 110px">
                <div class="content-item">
                  <span class="title-stats">{{ $t("Tasks in Progress") }}</span>
                  <v-progress-circular
                    :rotate="360"
                    :size="100"
                    :width="4"
                    :value="pr.tasks_in_progress_rate"
                    color="red"
                  >
                    {{ pr.tasks_in_progress }}
                  </v-progress-circular>
                </div>
              </div>
              <div class="col col-print-3" style="width: 90px">
                <div class="content-item">
                  <div class="number-stats">
                    <i class="fal fa-tasks"></i> {{ pr.open_tasks }}
                    <span>{{ $t("Open Tasks") }}</span>
                  </div>
                </div>
              </div>
              <div class="col col-print-3" style="width: 90px">
                <div class="content-item">
                  <div class="number-stats">
                    <i class="fal fa-book"></i> {{ pr.open_threads }}
                    <span>{{ $t("Open Threads") }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
			  -->
 
            <!-- {{tickets}} / {{months}} -->

            <!-- {{this.date}} -->
         <div class="row-item">
          <div class="width-full full-width" style="width: 100%">
            <div class="kv-planner" id="kv-planner">
              <div class="kv-a-planner">
                <a href="#kv-planner"><i class="fal fa-expand-wide"></i></a>
                <a @click="goToDate()"
                  ><i class="fal fas fa-calendar-week"></i
                ></a>
              </div>

              <div class="col-all" v-dragscroll>
                <div class="planner-header">
                  <div class="month-part">
                    <span
                      v-for="mm in months"
                      class="month-title"
                      :style="{ width: mm.size * 17.856 + 'px' }"
                    >
                      <strong> {{ mm.text }} ({{mm.size}} gün)</strong>
                    </span>
                  </div>

                  <div class="week-row">
                    <div class="fixed-row first"></div>
                  </div>

                  <span
                    v-for="dd in dates"
                    class="fixedTitle"
                    :id="'dt-' + dd[2]"
                    :class="{ today: thisWeek == dd[2] }"
                  >
                    <strong>{{ dd[2] }}. {{ $t("Week") }} </strong>
                    <br />
                    {{ dd[0] }}/{{ dd[1] }} - 
                  </span>
                </div>
                <div
                  class="week-row"
                  v-for="(item, i) in tickets"
                  :key="item.id"
                >
                  <div class="fixed-row first">
                    <span :title="item.subject">{{
                      item.subject
                    }}</span>
                  </div>
                  <div
                    class="fixed-row"
                    v-for="dd in dates"
                    :class="{ today: thisWeek == dd[2] }"
                  >
                    <div
                      v-if="item && item.plans && item.plans[dd[3]]"
                      v-for="li in item.plans[dd[3]]"
                      class="kv-plan-item"
                      :class="getPlanItemClass(li, i)"
											:title="li.name"
                    >
                      {{ li.name }} 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 

        <div class="row-item" v-if="1 == 2">
          <div class="width-full full-width" style="width: 100%">
            <div class="planner">
         
              <div class="gant-wrapper">
                <div class="gantt">
                  <div class="gantt__row gantt__row--months">
                    <div class="gantt__row-first header-first fixedTitle"></div> 
                    <span v-for="dd in dates" class="fixedTitle"
                      >{{ dd[2] }}. {{ $t("Week") }} <br />
                      {{ dd[0] }}/{{ dd[1] }}
                    </span>
                  </div>
                  <div class="gantt__row gantt__row--lines" data-month="5">
                    <span style="width: 250px"></span> 
                    <span class="marker" v-for="i in diffWeeks"></span>
                  </div>
                  <div
                    class="gantt__row"
                    v-for="(item, i) in sections"
                    :key="item.name"
                  >
                    <div class="gantt__row-first">
                      {{ item.section_name }}
                    </div> 
                    <ul class="gantt__row-bars" v-for="li in item.plans">
                      <li
                        :style="{ gridColumn: li.date }"
                        style="background-color: #2ecaac"
                        :title="li.name"
                      >
                        {{ li.name }}
                      </li>
                    </ul>
                  </div>
               
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row-item status-report">
          <div class="col-12">
            <div class="label">
              {{ $t("Status Report") }}
              <div class="search">
                <v-switch
                  v-model="showCompleted"
                  :label="`${
                    showCompleted == 1 ? 'Show Open Sections' : 'Show Completed'
                  }`"
                  value="1"
                  size="mini"
                  @change="getSections()"
                ></v-switch>
              </div>
            </div>
            <div class="content"> 
              <v-data-table
                class="simple-table"
                :sort-by="['section_name']"
                :sort-desc="[false]"
                :headers="headers"
                :items="tickets"
                hide-default-footer
                :items-per-page="sections.length"
                dense
              >
                <template v-slot:item.cfd_section_status_color="{ item }">
                  <span
                    :style="{
                      color: '#' + item.cfd_section_status_color,
                    }"
                    >{{ item.cfd_section_status_label }}
                  </span>
                </template>
            
              <template v-slot:default>
              <thead >
                <tr>
                  <th class="text-left" style="width: 300px">
                    {{ $t("Section") }}
                  </th>
                  <th class="text-left">{{ $t("Last Message") }}</th>
                  <th class="text-left" width="160px">{{ $t("Status") }}</th>
                  <th class="text-center" width="100px">{{ $t("Date") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in tickets" :key="item.name">
                  <td>{{ item.subject }}</td>
                  <td>{{ item.last_update }}</td>
                  <td>
                    <span
                   
                      :style="{
                        color: '#' + item.cfd_section_status_color,
                      }"
                      >{{ item.cfd_section_status_label }}
                    </span>
                  </td>
                  <td>
                    <span v-if="item.last_updated_on">
                      {{ item.last_updated_on | date }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
            </v-data-table>
            </div>
          </div>
        </div>
        <div class="row-item status-report">
          <div class="col-12">
            <div class="label">{{ $t("Upcoming Milestone") }}</div>
            <div class="content">
              <v-timeline dense clipped>
                <v-timeline-item
                  v-for="tm in milestones"
                  fill-dot
                  class="black--text mb-12"
                  :color="tm.color"
                  small
                >
                  <v-row justify="space-between">
                    <v-col
                      class="text-left"
                      cols="1"
                      :style="{ width: '100px' }"
                    >
                      <span v-if="tm.due_date"> {{ tm.due_date | date }}</span>
                    </v-col>
                    <v-col
                      cols="11"
                      class="font-weight-300"
                      v-text="tm.subject"
                    ></v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SingleProjectProxy } from "@/proxies/SingleProjectProxy";
import { SectionsProxy } from "@/proxies/SectionsProxy";
import { MilestoneProxy } from "@/proxies/MilestoneProxy";
import { ProjectPlansProxy } from "@/proxies/ProjectPlansProxy";
import moment from "moment";
import { dragscroll } from "vue-dragscroll";


import {
  DxGantt,
  DxTasks,
  DxDependencies,
  DxResources,
  DxResourceAssignments,
  DxColumn,
  DxEditing,
	DxContextMenu,
  DxValidation,
  DxToolbar,
  DxItem
} from "devextreme-vue/gantt";
import "devexpress-gantt/dist/dx-gantt.css";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { exportGantt as exportGanttToPdf } from "devextreme/pdf_exporter";
import { ThreadsProxy } from '@/proxies/ThreadsProxy'

const ganttRef = "gantt";


export default {
  directives: {
    dragscroll,
  },
	components: {
    DxGantt,
    DxTasks,
    DxDependencies,
    DxResources,
    DxResourceAssignments,
    DxColumn,
    DxEditing,
		DxContextMenu,
    DxValidation,
    DxToolbar,
    DxItem
  },
	computed: {
    gantt() {
      return this.$refs[ganttRef].instance;
    },
	},
  data() {
    return {
      pr: {},
      loading: false,
      loading2: false,
      showCompleted: 0,
      diffWeeks: 0,
      id: null,
      value: 50,
      value2: 60,
      value3: 80,
      sections: [],
      plans: [],
      date: { first: null, last: null },
      milestones: [],
      dates: [],
      months: [],
      thisWeek: 0,
			
			exportButtonOptions: {
        text: "Export to PDF",
        hint: "Export to PDF",
        icon: "exportpdf",
        stylingMode: "contained",
        onClick: () => {
          this.exportGantt();
        },
      },

      viewSelectBoxOptions: {
        label: "View Mode",
        labelMode: "floating",
        width: 300,
        items: [
          {id:25, "name": "Tasks Only"},
          {id:26, "name": "Sections & Tasks"},
          {id:27, "name": "Feature/Change Requests & Tasks"},
          {id:28, "name": "Feature/Change Requests Only"},
          {id:29, "name": "Sections & Features/Changes & Tasks"},
          {id:30, "name": "Members & Tasks"},
          {id:31, "name": "Members & Features/Changes & Tasks"}
        ],
        valueExpr: "id",
        displayExpr: "name",
        value: 25,
        onValueChanged: (args) => {
          // console.log("selected view mode: ", args.value);
          this.getTasks(args.value);
        },
      },
      startWeek: 0,
			tickets:[],
			totalTasks:null,
			filters:{
				page: 1,
        rowsPerPage: 50,
        sort: {
          name: ['record_id'],
          desc: [false],
        },
        projectId: [],
        caseStatus: '1',
        priority: [],
        keyword: '',
        dates: [],
        brand:[]
			},
      headers: [
        {
          text: "Bölüm",
          align: "start",
          value: "section_name",
        },
        { text: "Son Mesaj", value: "last_update" },
        { text: "Durum", value: "cfd_section_status_label" },
        { text: "Date", value: "last_updated_on" },
      ],
    };
  },
  created() {
    this.thisWeek = moment().startOf("isoWeek").isoWeek();
    this.id = this.$route.params.id;
    this.getMilestones();
    this.getProjects();
    // this.getSections();
    this.getRequests();
    this.setDates();
  },
  methods: {
		onTaskClick(){

		},
    getPlanItemClass(li, i) {
      let ix = i % 10;
			let lPositive = li.length < 0 ? li.length * - 1 : li.length;
      return "plan-length-" + lPositive + " clr-" + ix;
    },
    changeDate(action) {
      if (action == "increase") {
        this.startWeek++;
      } else if (action == "today") {
        this.startWeek = 0;
      } else {
        this.startWeek--;
      }
      // console.log("this.startWeek", this.startWeek);

      this.setDates();
    },
    goToDate(week) {
      if (!week) {
        week = this.thisWeek;
      }
      var elmnt = document.getElementById("dt-" + week);
      elmnt.scrollIntoView();
    },
    setDates(date) {

      this.loading2 = true;
      if (!date) {    date = moment().format("YYYY-MM-DD");    }
      this.dates = [];
      this.months = [];
 
      let fWeek = moment(this.date.first)
        .startOf("isoWeek")
        .format("YYYY-MM-DD");
      let lWeek = moment(this.date.last)
        .startOf("isoWeek")
        .format("YYYY-MM-DD");
      let lWeekEnd = moment(this.date.last)
        .endOf("isoWeek")
        .format("YYYY-MM-DD");
      let diff = moment(lWeek).diff(fWeek, "week");
      this.diffWeeks = diff;
      let df = diff < 13 ? 12 : diff;
      for (let i = 0; i <= df; i++) {
        let start = moment(fWeek).add(i, "weeks"); 
        this.dates.push([
          start.startOf("isoWeek").format("DD.MM"),
          start.endOf("isoWeek").format("DD.MM"),
          start.isoWeek(),
          start.startOf("isoWeek").format("YYYY-MM-DD"),
        ]);
      }
      this.findMonths(fWeek, lWeekEnd);
			// console.log(" this.dates",date, this.date,this.diffWeeks,df,lWeek,lWeekEnd)
			if( this.dates[0] &&  this.dates[0][3]){
      let stWeek = this.dates[0][3];

						let filteredPlans = this.tickets.map((p) => {
							let st = moment(p.start).startOf("isoWeek");
							let end = moment(p.end).startOf("isoWeek");
							let week = moment(p.start).week();
							let length = moment(p.start).diff(moment(p.end), "week") + 1;
							let week2 = this.dates[0][2];

							let first = st.diff(stWeek, "week") + 1;
							let last = end.diff(stWeek, "week") + 2;

							if (first <= 0 && last > 1 && last < 13) {
								first = 1;
							}
							let date = first + "/" + last;

							return {
								first,
								last,
								date,
								week_start_date: moment(p.start_date)
									.startOf("isoWeek")
									.format("YYYY-MM-DD"),
								week_end_date: moment(p.end_date)
									.startOf("isoWeek")
									.format("YYYY-MM-DD"),
								length,
								name: p.subject ? p.subject : p.start_date + "-" + p.end_date,
								id: p.id,
							};
						});

						this.tickets = this.tickets.map((s) => {
							let plans = filteredPlans.filter((p) => p.id == s.record_id);
							let past = filteredPlans.filter(
								(p) => p.id == s.record_id && p.first < 0
							).length;
							let next = filteredPlans.filter(
								(p) => p.id == s.record_id && p.first >= 13
							).length;

							let plan_w_date = {};

							plans.forEach((pm) => {
								let date = pm.week_start_date;
								if (!plan_w_date[date]) {
									plan_w_date[date] = [];
								}
								plan_w_date[date].push(pm);
							});

							return { ...s, plans: plan_w_date, past, next };
						});
			}
      this.loading2 = false;
    },
    findMonths(startDate, endDate) {
      var start = moment(startDate).format("YYYY-MM-DD").split("-");
      var end = endDate.split("-");
      var startYear = parseInt(start[0]);
      var endYear = parseInt(end[0]);
      var dates = [];

      for (var i = startYear; i <= endYear; i++) {
        var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
        var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
        for (
          var j = startMon;
          j <= endMonth;
          j = j > 12 ? j % 12 || 11 : j + 1
        ) {
          var month = j + 1;
          var displayMonth = month < 10 ? "0" + month : month;
          dates.push([i, displayMonth, "01"].join("-"));
        }
      }

      dates.forEach((d,i) => {
        let swd = moment(d).startOf("isoWeek");
        let ewd = moment(d).endOf("isoWeek");
        let smd = moment(d).startOf("month");
        let emd = moment(d).endOf("month");
        let total = moment(d).daysInMonth();
        // let dfs = smd.diff(moment(startDate), "days",true);
		
				let date1 = new Date(smd.format("MM/DD/YYYY"));
				let date2 =   new Date(moment(startDate).format("MM/DD/YYYY"));
				let diffTime = Math.abs(date2 - date1);
				let dfs = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

				let edate1 = new Date(emd.format("MM/DD/YYYY"));
				let edate2 =  new Date(moment(endDate).format("MM/DD/YYYY"));
				let ediffTime = Math.abs( edate1 - edate2);
				let dfe = Math.ceil(ediffTime / (1000 * 60 * 60 * 24)); 
				
        // let dfe = moment(endDate).diff(emd, "days",true);
        // let dfe = moment(endDate).diff(emd, "days",true);
        let size = total;
	 
       	if(i==0){
					 size = size - dfs ;
				}else
       	if(i==dates.length-1){
					 size = size - dfe ;
				}else
        if (dfs >= 0 && dfe >= 0) {
					//  size = size - dfs ;
        } else if (dfs >= 0 && dfe < 0) {
          size = size + dfe ;
        } else if (dfs < 0 && dfe < 0) {
          size = 0;
        } else if (dfs < 0 && dfe >= 0) {
          size = size + dfs + 2;
        }
        this.months.push({ date: d, text: moment(d).format("MMM YY"), size });
      });
    },

    printPage() {
      // window.print();
      let id = this.$route.params.id;
      window.open("/project/"+id+"/print", '_blank');
    },
    nameReduce(user) {
      if (user) {
        let s = user.split(" ");
        let a = s[0] ? s[0][0] : "";
        a = s[1] && s[1][0] && s[1][0] != "-" ? a + s[1][0] : a;
        a = s[2] && s[2][0] && s[2][0] != "-" ? a + s[2][0] : a;
        return a;
      }
    },
    getMilestones() {
      new MilestoneProxy()
        .setParameters({
          projectId: this.$route.params.id,
          sort: {
            name: ["due_date"],
            desc: [true],
          },
        })
        .all()
        .then((response) => {
          this.milestones = response.data;
        });
    },
    getSections() {
      new SectionsProxy()
        .setParameters({
          showCompleted: this.showCompleted,
          projectId: this.$route.params.id,
          dateRange: [
            moment()
              .add(this.startWeek, "weeks")
              .startOf("isoWeek")
              .format("YYYY-MM-DD"),
            moment()
              .add(this.startWeek + 12, "weeks")
              .endOf("isoWeek")
              .format("YYYY-MM-DD"),
          ],
          sort: {
            name: ["sort_order"],
            desc: [true],
          },
        })
        .all()
        .then((response) => {
          // this.sections =  response.data.map(e=>{
          //      // toplam 12 hafta olacak şekilde
          //      //
          //     let t=Math.floor(Math.random() * 3);

          //     let plans = []
          //     for(let z=1; z<t; z++){
          //         let s=Math.floor(Math.random() * 10);;
          //         let a=Math.round(Math.random(1,5)*10);
          //         plans.push({date: a+'/'+(a+s), name:"Test"});
          //     }
          //     return {...e,plans}
          // })
          this.sections = response.data;
          this.getPlans();
        });
    },
    getPlans() {
      new ProjectPlansProxy()
        .setParameters({
          projectId: this.$route.params.id,
          sort: {
            name: ["start_date"],
            desc: [true],
          },
        })
        .all()
        .then((response) => {
          this.plans = response.data;
          this.date.first = new Date(
            Math.min(...response.data.map((e) => new Date(e.start_date)))
          );
          this.date.last = new Date(
            Math.max(...response.data.map((e) => new Date(e.end_date)))
          );
          if (this.date.last) {
            this.date.last = moment(this.date.last).format("YYYY-MM-DD");
          }
          if (this.date.first) {
            this.date.first = moment(this.date.first).format("YYYY-MM-DD");
          }
          this.setDates();
        });
    },
		getRequests() {
     
      this.loading = true
      let parameters = {
        request_type:2
      }
      parameters.page = this.filters.page
      parameters.sort = this.filters.sort
      parameters.dates = this.filters.dates
      parameters.rowsPerPage = this.filters.rowsPerPage
      parameters.keyword = this.filters.keyword
      parameters.projectId = this.id
      parameters.priority = this.filters.priority
      parameters.request_status  =  this.filters.requestStatus
// console.log("this.filters.caseStatus",this.filters.caseStatus,parameters.caseStatus )
      if (this.filters.caseStatus && this.filters.caseStatus=="2") {
        parameters.caseStatus = this.status.find((k) => {
          return k.id == this.filters.caseStatus
        })
        parameters.caseStatus = parameters.caseStatus ? parameters.caseStatus.value : ''
      } 
      if (this.filters.brand && this.filters.brand) {
        parameters.brand = this.filters.brand 
      }

      parameters.categoryId = this.filters.category
 
      new ThreadsProxy()
      .setFields([
        'case_status',
        'request_status',
        'estimated_start_date',
        'estimated_delivery_date',
        'estimated_total_effort',
        'cfd_request_status_color',
        'cfd_request_status_value',
        'cfd_request_status_label',
        'cfd_request_type',
        'cfd_case_status_color',
        'cfd_case_status_label',
        'cfd_case_status_value',
        'cfd_message_status_label',
        'cfd_message_status_value',
        'cfd_priority_color',
        'cfd_priority_label',
        'cfd_priority_value',
        'closed_on',
        'created_on',
        'customer_comments_count',
        'hold_reason',
        'inbox_thread_id',
        'labels',
        'last_message_date',
        'message_count',
        'message_status',
        'priority',
        'record_id',
        'rel_hold_reason_label',
        'sender_email',
        'sender_name',
        'subject',
      ])
        .setParameters(parameters)
        .all()
        .then((response) => {
          this.loading = false;

 
      response.data = response.data.map((a) => {
            let mn = { ...a }
            mn.threadOptionVisibility = false
						mn.id = mn.record_id;
						mn.start_date = mn.estimated_start_date;
						mn.end_date = mn.estimated_delivery_date;
						mn.start = mn.estimated_start_date;
						mn.end = mn.estimated_delivery_date;
						mn.plan=[{}];
            return mn
          })

           let dt = response.data.filter((a) => {  return a.start_date && a.end_date  })

          this.date.first = new Date(
            Math.min(...dt.map((e) => new Date(e.start_date)))
          );
          this.date.last = new Date(
            Math.max(...dt.map((e) => new Date(e.end_date)))
          );
          if (this.date.last) {
            this.date.last = moment(this.date.last).format("YYYY-MM-DD");
          }
          if (this.date.first) {
            this.date.first = moment(this.date.first).format("YYYY-MM-DD");
          }
         
					// console.log(" this.date", this.date, response.data)

     
						this.tickets = response.data
						this.totalTasks = response.totalCount
						this.setDates();
        })


      //       let pm = {...parameters};
      // pm.forExport = 1 

      // new ThreadsProxy()
      //   .setParameters(pm)
      //   .all()
      //   .then((response) => {
      //     this.loading = false 
      //     this.url = response.url;
      //     this.filename = response.filename;
      //   })
  
    },
    getProjects() {
      let id = this.$route.params.id;
      this.loading = true;
      new SingleProjectProxy()
        .setParameters({
          recordId: this.id,
          record_id: this.id,
          projectId: this.id,
          detailed: 1,
        })
        .all()
        .then((response) => {
          // console.log("response,", response);
          this.pr = response.data.find((k) => k.record_id == this.id);
          this.pr.tasks_in_backlog_rate = parseInt(
            (this.pr.tasks_in_backlog / this.pr.open_tasks) * 100
          );
          this.pr.tasks_in_dependent= this.pr.tasks_in_dependent ? this.pr.tasks_in_dependent : 0;
          this.pr.tasks_in_dependent_rate = parseInt(
            (this.pr.tasks_in_dependent / this.pr.open_tasks) * 100
          );
          this.pr.tasks_in_progress_rate = parseInt(
            (this.pr.tasks_in_progress / this.pr.open_tasks) * 100
          );
          this.pr.members = this.pr.members;

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
a.print {
  float: right;
  margin-right: 15px;
  font-size: 22px;
  color: #465669;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@media print {
  @page {
    size: auto; /* auto is the initial value */
    margin: 0mm; /* this affects the margin in the printer settings */
    padding: 0mm; /* this affects the margin in the printer settings */
  }
  body * {
    visibility: visible;
    overflow: visible;
  }

  p {
    page-break-before: always;
  }
  .v-application--wrap {
    visibility: visible;
    overflow: visible;
  }
  .kiva-customer-portal {
    visibility: visible;
    overflow: visible;
  }
  .height-full {
    visibility: visible;
    overflow: visible;
  }
  header.bg-navy-blue {
    display: none;
  }
  .col-print-12 {
  }
  .container {
    padding: 0;
    margin: 0;
    overflow: visible;
  }
  .project-container {
    border-radius: 0px;
    padding: 0;
    border: 0px solid #fff;
    box-shadow: rgba(255, 255, 255, 0) 0px 0px 0px 0px;
    background: #fff;
    color: #465669;
    overflow: visible;
    .header {
      width: 100%;
      padding: 15px 15px;
      font-size: 16px;
      font-weight: 600;
      border-bottom: 1px solid #d9e1ee;
    }
  }
  .content-item {
    margin-right: 10px;
    display: block;
    text-align: center;
    position: relative;

    i {
      display: block;
      margin: 3px;
    }
    .number-stats {
      background: #f6f8fc;
      border: 1px solid #d9e1ee;
      border-radius: 4px;
      height: 75px;
      width: 80px;
      font-size: 22px;
      text-align: center;
      padding: 10px 0px;
      color: #ffa800;
      span {
        color: #000;
        font-size: 10px;
        width: 100%;
        display: block;
      }
    }
  }
  .pr-teams {
    width: 100%;
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    flex: 1 1 auto;
    &:first-child,
    &:nth-child(4),
    &:nth-child(8) {
      padding-left: 0px;
      .team-member {
        padding-left: 0px;
      }
    }
    &:nth-child(5),
    &:nth-child(10) {
      border-right: 0px solid #d9e1ee;
      .team-member {
        border-right: 0px solid #d9e1ee;
      }
    }

    .team-member {
      display: block;
      width: 100%;
      min-width: auto;
      margin-bottom: 10px;
      position: relative;
      margin-bottom: 12px;
      border-right: 1px solid #d9e1ee;
      padding-left: 40px;
      .headline {
        font-size: 1rem !important;
      }
    }
  }
}

.pr-teams {
  width: 100%;
  padding-top: 10px;
  display: inline-flex;
  position: relative;
  width: 100%;
  overflow: auto;
  &:first-child,
  &:nth-child(4),
  &:nth-child(8) {
    padding-left: 0px;
    .team-member {
      padding-left: 0px;
    }
  }
  &:nth-child(5),
  &:nth-child(10) {
    border-right: 0px solid #d9e1ee;
    .team-member {
      border-right: 0px solid #d9e1ee;
    }
  }

  .team-member {
    display: inline-flex;
    width: 100%;
    min-width: auto;
    margin-bottom: 10px;
    position: relative;
    margin-bottom: 12px;
    border-right: 1px solid #d9e1ee;
    padding-left: 40px;
    .headline {
      font-size: 1rem !important;
    }
    &:first-child {
      padding-left: 0px;
    }
    &:nth-child(4),
    &:nth-child(8) {
      border-right: 0px solid #d9e1ee;
      .team-member {
        border-right: 0px solid #d9e1ee;
      }
    }

    .title {
      width: 250px;
      margin-left: 10px;
      .fullname {
        font-size: 14px;
        color: #201f1e;
        line-height: 1.5;
        padding-right: 6px;
      }
      .jobtitle {
        font-size: 12px;
        color: #605e5c;
        line-height: 1.3;
      }
      .divider-border {
        border-right: 1px solid #afafaf;
        position: absolute;
        right: 3px;
        height: 25px;
        top: 10px;
      }
    }
  }
}
.status-report {
  margin-top: 10px;
  .label {
    font-weight: 300;
    width: 100%;
    font-size: 15px;
    color: #91a0b4;
    line-height: 2;
    margin-left: 0px;
    margin-bottom: 0px;
    display: inline-flex;
    justify-content: space-between;
    span {
      width: 200px;
    }
    .search {
      width: 200px;
      margin-right: 20px;
    }
  }
  .v-input--selection-controls {
    margin-top: 0px !important;
  }
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 13px !important;
  height: 30px !important;
  color: #3e3e3c;
  font-weight: 300;
  padding-top: 10px;
  padding-bottom: 10px;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  height: 30px !important;
  color: #3e3e3c;
  font-size: 14px !important;
  border-right: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
  &:last-child {
    border-right: 0px solid #d2d2d2;
  }
}
.simple-table {
  thead {
    background: #fafaf9;
    th {
      height: 30px;
    }
  }
}
a.action-button {
  padding: 6px;
  border-radius: 5px;
  margin: 10px 2px 10px 12px;

  background: #f6f8fc;
  border: 1px solid #d9e1ee;
  color: #465669;
}
.section-badge {
  padding: 6px 10px;
  border-radius: 5px;
  display: block;
  color: #fff;
  width: fit-content;
  height: 33px;
  overflow: none;
}
.project-container {
  border-radius: 6px;
  padding: 0px;
  border: 1px solid #f9f9f9;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #fff;
  color: #465669;

  .header {
    width: 100%;
    padding: 20px 15px;
    font-size: 18px;
    font-weight: 600;
    border-bottom: 2px solid #d9e1ee;
  }

  .row-item {
    width: 100%;
    padding: 7px 5px;
    font-size: 15px;
    border-bottom: 2px solid #d9e1ee;

    flex-wrap: wrap;
    position: relative;
    flex: 1 1 auto;
    &.inlne-flex {
      display: inline-flex;
    }

    .label {
      color: #91a0b4;
      width: 100%;
      min-width: 170px;
      position: relative;
      margin-right: 10px;
      align-self: center;
      padding-bottom: 4px;
      &.margin-bottom-20 {
        margin-bottom: 10px;
      }
    }
    .content {
      width: 100%;
      color: #465669;
      font-weight: 500;
    }

    .content-item {
      margin-right: 15px;
      display: block;
      text-align: center;
      position: relative;
      .title-stats {
        padding-top: 0px;
        font-size: 11px;
        position: absolute;
        top: 60px;
        left: 10px;
        width: 80px;
        text-align: center;
      }
      i {
        display: block;
        margin: 3px;
      }
      .number-stats {
        background: #f6f8fc;
        border: 1px solid #d9e1ee;
        border-radius: 4px;
        height: 95px;
        width: 80px;
        font-size: 20px;
        text-align: center;
        padding: 10px 0px;
        color: #ffa800;

        span {
          color: #000;
          font-size: 10px;
          width: 100%;
          display: block;
        }
      }
    }
  }
}
.member-container {
  padding: 0px 0px 10px 0px;
}

.gant-header {
  color: #202125;
  margin-bottom: 40px;
  h2 {
    font-weight: 600;
  }
  p {
    font-weight: 300;
  }
}
$red: #ff6252;

.gant-wrapper {
  max-width: 1200px;
  min-width: 700px;
  margin: 0 auto;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}
.gantt {
  display: grid;
  border: 0;
  border-radius: 12px;
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 75px 125px -57px #7e8f94;

  width: 100%;
  .fixedTitle {
    position: sticky !important;
    top: 105px;
    z-index: 999;
  }
  &__row {
    display: grid;
    grid-template-columns: 250px repeat(3, 1fr);

    //  grid-auto-flow: column;
    background-color: #fff;
    &:nth-child(odd) {
      background-color: #f5f5f5;
      .gantt__row-first {
        text-align: left;
      }
      .gantt__row-first,
      .gantt__row-next,
      .gantt__row-past {
        background-color: #f5f5f5;
      }
    }
    .gantt__row-bars {
      padding-left: 0;
    }
    &:nth-child(3) {
      .gantt__row-bars {
        border-top: 0;
        padding-left: 0;
      }
      .gantt__row-first {
        border-top: 0;
        text-align: left;
        padding-left: 10px;
        width: 250px;
      }
    }
    &--empty {
      background-color: lighten($red, 25%) !important;
      z-index: 1;
      .gantt__row-first,
      .gantt__row-next,
      .gantt__row-past {
        border-width: 1px 1px 0 0;
        text-align: left;
      }
    }
    &--lines {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: transparent;
      grid-template-columns: 250px repeat(3, 1fr);
      //  grid-auto-flow: column;
      text-align: left;
      span {
        display: block;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        &.marker {
          background-color: rgba(232, 240, 244, 0.13);
          z-index: 2;
        }
      }
      &:after {
        grid-row: 1;
        grid-column: 0;
        background-color: #1688b345;
        z-index: 2;
        height: 100%;
      }
    }
    &--months {
      color: #0a3444;
      background-color: rgb(210, 225, 240) !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      grid-template-columns: 250px repeat(3, 1fr);

      //  grid-auto-flow: column;
      text-align: left;

      .gantt__row-first {
        border-top: 0 !important;
        text-align: left;
        background-color: #e8e8e8 !important;
        width: 250px;
        &.header-first {
          background: #fff;
          width: 250px;
          a {
            cursor: pointer;
            font-size: 15px;
            z-index: 10;
          }
        }
      }
      span {
        text-align: center;
        font-size: 11px;
        align-self: center;
        font-weight: bold;
        padding: 20px 0;
      }
    }
    &-first {
      background-color: #fff;
      border-width: 1px 0 0 0;
      border-color: rgba(0, 0, 0, 0.1);
      border-style: solid;
      padding: 15px 0;
      font-size: 12px;
      font-weight: bold;
      text-align: left;
      padding-left: 10px;
    }
    &-past,
    &-next {
      background-color: #fff;
      border-width: 1px 0 0 0;
      border-color: rgba(0, 0, 0, 0.1);
      border-style: solid;
      padding: 15px 0;
      font-size: 13px;
      font-weight: bold;
      text-align: center;
    }
    &-bars {
      list-style: none;
      display: grid;
      padding: 9px 0;
      margin: 0;
      grid-template-columns: 250px repeat(3, 1fr);
      //  grid-auto-flow: column;
      grid-gap: 8px 0;
      border-top: 1px solid rgba(221, 221, 221, 0.8);
      li {
        font-weight: 500;
        text-align: left;
        font-size: 14px;
        height: 33px;
        background-color: #55de84;
        padding: 5px 12px;
        color: #fff;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        border-radius: 20px;

        &.stripes {
          background-image: repeating-linear-gradient(
            45deg,
            transparent,
            transparent 5px,
            rgba(255, 255, 255, 0.1) 5px,
            rgba(255, 255, 255, 0.1) 12px
          );
        }

        &:before,
        &:after {
          content: "";
          height: 100%;
          top: 0;
          z-index: 4;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.3);
        }
        &:before {
          left: 0;
        }
        &:after {
          right: 0;
        }
      }
    }
  }
}

.font-weight-300 {
  font-weight: 300;
}
.v-label {
  font-size: 14px !important;
}
.v-application .mb-12 {
  margin-bottom: 8px !important;
}

.sc-rows .title {
  background: #fff !important;
  color: #616161;
  span {
    font-size: 14px;
    font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
  }
}

.kv-planner {
  width: 100%;
  display: inline-flex;
  flex: auto;
  position: relative;
  .col-first {
    width: 250px;
  }
  .col-second {
    overflow: auto;
    .week-row {
      border-right: 1px solid #aaa;
    }
  }
  .col-all {
    height: calc(100vh - 70px);
    border: 1px solid #efefef;
    overflow: auto;
    .week-row {
    }
  }

  .kv-a-planner {
    background: #f5f5f5;
    width: 250px;
    height: 85px;
    padding: 8px 10px;
    a {
      margin-left: 10px;
      i {
        font-size: 20px;
      }
    }
    position: absolute;
    left: 00px;
    top: 00px;
    z-index: 6;
  }

  .planner-header {
    width: fit-content;
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 5;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(76, 76, 76, 0.1);
    box-shadow: 0px 1px 2px 0px rgba(76, 76, 76, 0.1);
    margin-bottom: 3px;
    height: 85px;
    .month-part {
      width: 100%;
      height: 20px;
      display: inline-block;
      padding-left: 249px;
      position: absolute;
			white-space: nowrap;
      top: -1px;
      .month-title {
        width: 125px;
        height: 26px;
        text-align: center;
        display: inline-block;
        background: #f3f4f5;
        border: 1px solid #d2d2d2;
        border-right: 0em solid #d2d2d2;
        border-top: 0em solid #d2d2d2;
        padding-top: 3px;
        color: #181818;
        overflow: hidden;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
    .header-first {
      width: 250px !important;
      min-height: 60px;
    }

    .fixedTitle {
      background-color: #fff;
      margin-top: 25px;
      width: 125px;
      height: 60px;
      font-size: 12px;
      display: inline;
      padding: 10px;
      text-align: center;
      border-right: 1px solid #d2d2d2;
      &.today {
        background: #f9f9f9;
      }
    }
  }
  .week-row {
    width: fit-content;
    display: flex;
    position: relative;

    .fixed-row {
      background-color: #fff;
      width: 125px;
      min-height: 34px;
      display: inline;
      padding: 6px 10px;
      text-align: center;
      position: static;
      overflow: visible;
      border-bottom: 1px solid #f1f1f1;
      border-right: 1px solid #f1f1f1;

      &.today {
        background: #f9f9f9;
      }
      &.first {
        width: 250px !important;
        text-align: left;
        position: sticky;
        left: 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f1f1f1;

        -webkit-box-shadow: 1px 0px 2px 0px rgba(76, 76, 76, 0.1);
        box-shadow: 1px 0px 2px 0px rgba(76, 76, 76, 0.1);
        height: auto;

        span {
          overflow: hidden;
          clear: both;
          font-size: 12px;
          display: inline-block;
          white-space: nowrap;
          width: 230px !important;
        }
      }
    }

    .kv-plan-item {
      background: #eeeff0;
      border-radius: 12px;
      padding: 3px;
      overflow: overlay;
      clear: both;
      display: inline-block;
      white-space: nowrap;
      border-top: 4px solid #efefef;

      &.clr-1 {
        border-color: #7e36fd;
      }
      &.clr-2 {
        border-color: #fdc100;
      }
      &.clr-3 {
        border-color: #18c4d1;
      }
      &.clr-4 {
        border-color: #fd7542;
      }
      &.clr-5 {
        border-color: #7e36fd;
      }
      &.clr-6 {
        border-color: #23b3f2;
      }
      &.clr-7 {
        border-color: #7e36fd;
      }
      &.clr-8 {
        border-color: #23b3f2;
      }
      &.clr-9 {
        border-color: #0070d2;
      }
      &.clr-0 {
        border-color: #f64e60;
      }
      &.plan-length-1 {
        width: calc(125px - 20px);
      }
      &.plan-length-2 {
        width: calc(250px - 20px);
      }
      &.plan-length-3 {
        width: calc(375px - 20px);
      }
      &.plan-length-4 {
        width: calc(500px - 20px);
      }
      &.plan-length-5 {
        width: calc(625px - 20px);
      }
      &.plan-length-6 {
        width: calc(750px - 20px);
      }
      &.plan-length-7 {
        width: calc(875px - 20px);
      }
      &.plan-length-8 {
        width: calc(1000px - 20px);
      }
      &.plan-length-9 {
        width: calc(1125px - 20px);
      }
      &.plan-length-10 {
        width: calc(1250px - 20px);
      }
    }
  }
}
</style>

<style lang="scss">
.gantt-container {
  height: calc(100vh - 105px);
  padding: 10px;
}

.gantt{

}

.gantt-task {
  max-height: 40px;
  height: 100%;
  display: block;
  overflow: hidden;
  background: #f8fafc;
  margin-top:2px;
  border: 1px solid #e2e8f0;
  border-radius: 0.275rem;
}

.gantt-task-wrapper {
  padding: 4px 8px 4px 8px;
  color: #64748b;
}

.gantt-task-wrapper > * {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gantt-task-avatar-wrap {
  float: left;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: 8px;
  background-color: #fff;
  overflow: hidden;
}

.gantt-task-img {
  width: 32px;
}

.gantt-task-title {
  font-weight: 500;
  font-size: 13px;
}

.gantt-task-row {
  font-weight: 400;
  font-size: 11px;
}

.gantt-task-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0%;
  height: 4px;
  background: rgba(0, 0, 0, 0.3);
}

.dx-gantt .dx-row {
  height: 48px;
}

</style>
